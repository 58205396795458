// import blog1 from  '../img/blogs/blog1.svg';
// import blog3 from  '../img/blogs/blog3.svg';
// import blog2 from  '../img/blogs/blog2.svg';
// import blog4 from  '../img/blogs/blog4.svg';
import blog1 from "../img/blogs/blog1.jpg"

const blogs = [
    {
        id: 1,
        title: 'Interesting situations in the process of portfolio preparation',
        date: '01',
        month: 'February',
        image: blog1
    },
]

export default blogs;